import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlightDrPepper from "../components/ProductHighlightDrPepper";
import ProductLinkListHorizonal from "../components/ProductLinkListHorizonal";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Content = () => {
  const title = "Dr Pepper";
  const description = "Dr Pepper is always fresh. Its signature blend of 23 flavors is made with love and makes every sip of Dr Pepper truly unique.";
  return (
    <Layout>
      <Seo title={title} description={description} />
      <Container>
        <Row>
          <Col>
            <h1>{title}</h1>
            <p>{description}</p>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h2>Nutrition Facts</h2>
            <p>Size 20 fl oz</p>
            <table class="nutrition-table">
              <thead>
                <tr>
                  <th>Per Serving</th>
                  <th>% DAILY VALUE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Calories: </b>250
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>Total Fat: </b>0 g
                  </td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>
                    <b>Sodium: </b>100 mg
                  </td>
                  <td>4%</td>
                </tr>
                <tr>
                  <td>
                    <b>Total Carbohydrates: </b>66 g
                  </td>
                  <td>22%</td>
                </tr>
                <tr>
                  <td>
                    <b>Sugar: </b>64 g
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>Protein: </b>0 g
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p className="mt-4 mb-3">*Based on a 2000 calorie diet</p>
            <h2 className="mt-4 mb-3">{title} Ingredients</h2>
            <ul>
              <li>Carbonated Water</li>
              <li>High Fructose Corn Syrup</li>
              <li>Caramel Color</li>
              <li>Phosphoric Acid</li>
              <li>Natural and Artificial Flavors</li>
              <li>Sodium Benzoate (preservative)</li>
              <li>Caffeine</li>
            </ul>
            <h2>Does Dr Pepper Have Caffeine?</h2>
            <p>Most cetrainly. If you look at the ingredients above you can see that Caffeine is one of the ingredients of Dr Pepper.</p>
            <p>The regular Dr Pepper has 28 mg of caffeine per 8 fluid ounces and:</p>
            <ul>
              <li>26.25 mg of caffeine per 7.5 fl Mini Can</li>
              <li>42 mg of caffeine per 12 fl regular sized Can</li>
              <li>59.15 mg of caffeine per 16.9 fl bottle</li>
              <li>70 mg of caffeine per 20 fl bottle</li>
              <li>118.349 mg of caffeine per 1 litre bottle</li>
              <li>153.99 mg of caffeine per 1.25 litre bottle</li>
              <li>236.705 mg of caffeine per 2 litre bottle</li>
            </ul>
            <h2>How many calories does Dr Pepper have?</h2>
            <p>
              Calorie amount varies depending on package size. The Nutrition Facts panel on each package will list the calorie content. Calories in a 12 fluid ounce portion of Regular Dr Pepper is 150
              calories.
            </p>
            <ul>
              <li>90 Calories per 7.5 fl Mini Can</li>
              <li>150 Calories per 12 fl regular sized Can</li>
              <li>250 Calories per 20 fl bottle</li>
            </ul>

            <h2>Does Dr Pepper have prune juice in it?</h2>
            <p>Dr Pepper contains no prune juice and is made up of a unique blend of natural and artificial flavors.</p>

            <h2>How much sugar does dr pepper have?</h2>
            <p>The sugar quantity of Regular Dr Pepper can be found on the dr pepper nutrition facts labels on each product.</p>
            <ul>
              <li>24 grams of Total Sugars which includes 24g of added sugar per 7.5 fl Mini Can</li>
              <li>39 grams of Total Sugars which includes 39g of added sugar 12 fl regular sized Can</li>
              <li>65 grams of Total Sugars which includes 65g of added sugar per 20 fl bottle</li>
            </ul>
          </Col>

          {/*
          <Col sm={12} className="mb-5">
            <h2 className="mt-4 mb-3">Available Sizes</h2>
            <ProductHighlightDrPepper />
				  </Col>
*/}
        </Row>

        <Row>
          <Col>
            <ProductLinkListHorizonal title="Products" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>References</h2>
            <ol class="reference-listing">
              <li>
                <OutboundLink target={`_BLANK`} href={`https://www.drpepper.com/en/products/drpepper`}>
                  Dr Pepper Regular Product Page
                </OutboundLink>
              </li>
              <li>
                <OutboundLink target={`_BLANK`} href={`https://www.drpepper.com/en/faq#17`}>
                  Dr Pepper FAQ Page (Caffeine)
                </OutboundLink>
              </li>
              <li>
                <OutboundLink target={`_BLANK`} href={`https://www.drpepper.com/en/faq#18`}>
                  Dr Pepper FAQ Page (Calories )
                </OutboundLink>
              </li>
            </ol>
            <p>Some values derived from base value.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Content;
